<template>
  <div id="detail">
    <detail-nav-bar class="detail-nav" @titleClick="titleClick" ref="nav"/>
    <scroll
      class="content"
      :mouse-wheel="true"
      :probe-type="3"
      @scroll="contentScroll"
      ref="scroll"
    >
      <detail-swiper :top-images="topImages" />
      <detail-base-info :goods="goods" />
      <detail-shop-info :shop="shop" />
      <detail-goods-info
        :detail-info="detailInfo"
        @detailImageLoad="detailImageLoad"
      />
      <detail-param-info :param-info="paramInfo" ref="param" />
      <detail-comment-info :comment-info="commentInfo" ref="comment" />
      <goods-list :goods="recommends" ref="recommend" />
    </scroll>
  </div>
</template>

<script>
import Scroll from "components/common/scroll/Scroll";
import GoodsList from "components/content/goods/GoodsList";

import DetailNavBar from "./childComps/DetailNavBar.vue";
import DetailSwiper from "./childComps/DetailSwiper.vue";
import DetailBaseInfo from "./childComps/DetailBaseInfo.vue";
import DetailShopInfo from "./childComps/DetailShopInfo.vue";
import DetailGoodsInfo from "./childComps/DetailGoodsInfo.vue";
import DetailParamInfo from "./childComps/DetailParamInfo.vue";
import DetailCommentInfo from "./childComps/DetailCommentInfo.vue";

import {
  getDetail,
  Goods,
  GoodsParam,
  Shop,
  getRecommend,
} from "network/detail";

import { debounce } from "common/utils";

export default {
  name: "Detail",
  components: {
    DetailNavBar,
    DetailSwiper,
    DetailBaseInfo,
    DetailShopInfo,
    Scroll,
    DetailGoodsInfo,
    DetailParamInfo,
    DetailCommentInfo,
    GoodsList,
  },
  data() {
    return {
      iid: null,
      topImages: [],
      goods: {},
      shop: {},
      detailInfo: {},
      paramInfo: {},
      commentInfo: {},
      recommends: [],
      themeTopYs: [],
      getThemeTopY: null,
      currentIndex: 0,
    };
  },
  created() {
    //   console.log(this.$route)
    this.iid = this.$route.params.id;

    //获取网络数据
    getDetail(this.iid).then((res) => {
      // console.log(res.result);
      //顶部轮播图
      this.topImages = res.result.itemInfo.topImages;
      //获取商品信息
      this.goods = new Goods(
        res.result.itemInfo,
        res.result.columns,
        res.result.shopInfo.services
      );
      //获取店铺信息
      this.shop = new Shop(res.result.shopInfo);
      //console.log(this.shop);
      //获取商品详细信息
      this.detailInfo = res.result.detailInfo;
      //console.log(this.detailInfo);
      //获取店铺商品信息
      this.paramInfo = new GoodsParam(
        res.result.itemParams.info,
        res.result.itemParams.rule
      );
      //获取评论信息
      if (res.result.rate.cRate !== 0) {
        this.commentInfo = res.result.rate.list[0];
      }

      // setTimeout(() => {
      //   this.$nextTick(() => {
      //     this.themeTopYs = [];
      //     this.themeTopYs.push(0);
      //     this.themeTopYs.push(this.$refs.param.$el.offsetTop);
      //     this.themeTopYs.push(this.$refs.comment.$el.offsetTop);
      //     this.themeTopYs.push(this.$refs.recommend.$el.offsetTop);
      //     console.log(this.themeTopYs);
      //   });
      // }, 1200);
    });

    // 获取推荐信息
    getRecommend().then((res) => {
      // console.log(res.data.list);
      this.recommends = res.data.list;
    });

    this.getThemeTopY = debounce(() => {
      this.themeTopYs = [];
      this.themeTopYs.push(0);
      this.themeTopYs.push(this.$refs.param.$el.offsetTop);
      this.themeTopYs.push(this.$refs.comment.$el.offsetTop);
      this.themeTopYs.push(this.$refs.recommend.$el.offsetTop);
      // console.log(this.themeTopYs);
    }, 300);
  },
  methods: {
    /**
     * 事件监听
     */
    // 图片加载调用
    detailImageLoad() {
      this.$refs.scroll.refresh();
      this.getThemeTopY();
    },
    //nav点击调用
    titleClick(index) {
      // console.log(this.themeTopYs[index]);
      this.$refs.scroll.scrollTo(0, -this.themeTopYs[index], 1000);
    },
    //监听Scroll
    contentScroll(position){
      //1.获取y值
      const positionY = -position.y;

      //2.将positionY的值和主题中的值进行对比
      let length = this.themeTopYs.length;
      for(let i = 0; i < length; i++){
        if(this.currentIndex !== i && ((i < length - 1 && positionY >= this.themeTopYs[i] && positionY < this.themeTopYs[i + 1]) || (i === length - 1 && positionY >=  this.themeTopYs[i]))){
          this.currentIndex = i;
          this.$refs.nav.currentindex = this.currentIndex;
        }
      }
      // for(let i = 0; i < length - 1; i++){
      //   if(this.currentIndex !== i && (positionY >= this.themeTopYs[i] && positionY < this.themeTopYs[i + 1])){
      //     this.currentIndex = i;
      //     this.$refs.nav.currentindex = this.currentIndex;
      //   }
      // }
      // this.listenShowBackTop(position);
    }

    /**
     * 网络请求
     */
  },
  mounted() {
    const refresh = debounce(this.$refs.scroll.refresh, 100);
    //监听事件总线
    this.$bus.$on("itemImageLoad", () => {
      this.$refs.scroll && refresh();
    });
  },
};
</script>

<style scoped>
#detail {
  height: 100vh;
  position: relative;
  z-index: 9;
  background-color: #fff;
}
.content {
  overflow: hidden;
  position: absolute;
  top: 44px;
  bottom: 58px;
  z-index: 9;
  background-color: #fff;
}
.detail-nav {
  position: relative;
  z-index: 10;
  background: #fff;
}
</style>>