<template>
  <div class="detail-nav-bar">
    <nav-bar>
      <div slot="left" class="back" @click="back">
          <img src="~assets/img/common/back.svg" alt="">
      </div>
      <div slot="center" class="title">
        <div
          v-for="(item, index) in titles"
          :key="index"
          class="title-item"
          :class="{ active: (index == currentindex) }"
          @click="itemClick(index)"
        >
          {{ item }}
        </div>
      </div>
      <!-- <div slot="right">右</div> -->
    </nav-bar>
  </div>
</template>

<script>
import NavBar from "components/common/navbar/NavBar";

export default {
  name: "DetailNavBar",
  components: {
    NavBar,
  },
  data() {
    return {
      titles: ["商品", "参数", "评论", "推荐"],
      currentindex: 0,
    };
  },
  methods: {
    itemClick(index) {
      // console.log(index);
      this.currentindex = index;
      this.$emit('titleClick',index)
    },
    back(){
        this.$router.back()
    }
  },
};
</script>

<style scoped>
.title {
  display: flex;
  font-size: 13px;
}
.title-item {
  flex: 1;
  text-align: center;
}
.active {
  color: var(--color-tint);
}
.back img{
    margin-top: 12px;
}
</style>